import {
    chakra
} from "@chakra-ui/react";

const Path = chakra("path", {
    shouldForwardProp: (prop) => true,
})

export function Layered_waves_1({ color1, color2, color3, ...rest }) {
    return (
        <chakra.svg {...rest} id="visual" viewBox="0 0 900 600" width="900" height="600" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" style={{ width: '100%' }}>
            <Path d="M0 434L21.5 429C43 424 86 414 128.8 405.7C171.7 397.3 214.3 390.7 257.2 398.8C300 407 343 430 385.8 430.7C428.7 431.3 471.3 409.7 514.2 404C557 398.3 600 408.7 642.8 405.8C685.7 403 728.3 387 771.2 379.2C814 371.3 857 371.7 878.5 371.8L900 372L900 601L878.5 601C857 601 814 601 771.2 601C728.3 601 685.7 601 642.8 601C600 601 557 601 514.2 601C471.3 601 428.7 601 385.8 601C343 601 300 601 257.2 601C214.3 601 171.7 601 128.8 601C86 601 43 601 21.5 601L0 601Z" fill={color1}></Path>
            <Path d="M0 504L21.5 498C43 492 86 480 128.8 467.3C171.7 454.7 214.3 441.3 257.2 445.3C300 449.3 343 470.7 385.8 482.2C428.7 493.7 471.3 495.3 514.2 487C557 478.7 600 460.3 642.8 448.7C685.7 437 728.3 432 771.2 436.5C814 441 857 455 878.5 462L900 469L900 601L878.5 601C857 601 814 601 771.2 601C728.3 601 685.7 601 642.8 601C600 601 557 601 514.2 601C471.3 601 428.7 601 385.8 601C343 601 300 601 257.2 601C214.3 601 171.7 601 128.8 601C86 601 43 601 21.5 601L0 601Z" fill={color2}></Path>
            <Path d="M0 544L21.5 541.5C43 539 86 534 128.8 533C171.7 532 214.3 535 257.2 537.5C300 540 343 542 385.8 540C428.7 538 471.3 532 514.2 528.2C557 524.3 600 522.7 642.8 525.5C685.7 528.3 728.3 535.7 771.2 536.7C814 537.7 857 532.3 878.5 529.7L900 527L900 601L878.5 601C857 601 814 601 771.2 601C728.3 601 685.7 601 642.8 601C600 601 557 601 514.2 601C471.3 601 428.7 601 385.8 601C343 601 300 601 257.2 601C214.3 601 171.7 601 128.8 601C86 601 43 601 21.5 601L0 601Z" fill={color3}></Path>
        </chakra.svg>
    )
}

export function Layered_waves_2({ color1, color2, color3, ...rest }) {
    return (
        <chakra.svg {...rest} id="visual" viewBox="0 0 900 600" width="900" height="600" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" style={{ width: '100%' }}>
            <Path d="M0 204L21.5 204.3C43 204.7 86 205.3 128.8 202C171.7 198.7 214.3 191.3 257.2 198C300 204.7 343 225.3 385.8 225.3C428.7 225.3 471.3 204.7 514.2 196C557 187.3 600 190.7 642.8 198C685.7 205.3 728.3 216.7 771.2 215.3C814 214 857 200 878.5 193L900 186L900 0L878.5 0C857 0 814 0 771.2 0C728.3 0 685.7 0 642.8 0C600 0 557 0 514.2 0C471.3 0 428.7 0 385.8 0C343 0 300 0 257.2 0C214.3 0 171.7 0 128.8 0C86 0 43 0 21.5 0L0 0Z" fill={color1}></Path>
            <Path d="M0 115L21.5 113.7C43 112.3 86 109.7 128.8 117.8C171.7 126 214.3 145 257.2 144.5C300 144 343 124 385.8 114.3C428.7 104.7 471.3 105.3 514.2 110C557 114.7 600 123.3 642.8 133.8C685.7 144.3 728.3 156.7 771.2 161.2C814 165.7 857 162.3 878.5 160.7L900 159L900 0L878.5 0C857 0 814 0 771.2 0C728.3 0 685.7 0 642.8 0C600 0 557 0 514.2 0C471.3 0 428.7 0 385.8 0C343 0 300 0 257.2 0C214.3 0 171.7 0 128.8 0C86 0 43 0 21.5 0L0 0Z" fill={color2}></Path>
            <Path d="M0 94L21.5 92.2C43 90.3 86 86.7 128.8 80.8C171.7 75 214.3 67 257.2 59.7C300 52.3 343 45.7 385.8 42.5C428.7 39.3 471.3 39.7 514.2 45C557 50.3 600 60.7 642.8 63.2C685.7 65.7 728.3 60.3 771.2 57.8C814 55.3 857 55.7 878.5 55.8L900 56L900 0L878.5 0C857 0 814 0 771.2 0C728.3 0 685.7 0 642.8 0C600 0 557 0 514.2 0C471.3 0 428.7 0 385.8 0C343 0 300 0 257.2 0C214.3 0 171.7 0 128.8 0C86 0 43 0 21.5 0L0 0Z" fill={color3}></Path>
        </chakra.svg>
    )
}

export function Layered_waves_3({ color1, color2, color3, ...rest }) {
    return (
        <chakra.svg {...rest} id="visual" viewBox="0 0 900 600" width="900" height="600" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" style={{ width: '100%' }}>
            <Path d="M0 455L21.5 448.5C43 442 86 429 128.8 422.2C171.7 415.3 214.3 414.7 257.2 412.3C300 410 343 406 385.8 412.2C428.7 418.3 471.3 434.7 514.2 427.7C557 420.7 600 390.3 642.8 385.7C685.7 381 728.3 402 771.2 413.2C814 424.3 857 425.7 878.5 426.3L900 427L900 601L878.5 601C857 601 814 601 771.2 601C728.3 601 685.7 601 642.8 601C600 601 557 601 514.2 601C471.3 601 428.7 601 385.8 601C343 601 300 601 257.2 601C214.3 601 171.7 601 128.8 601C86 601 43 601 21.5 601L0 601Z" fill={color1}></Path>
            <Path d="M0 469L21.5 471.8C43 474.7 86 480.3 128.8 474.2C171.7 468 214.3 450 257.2 452C300 454 343 476 385.8 480.7C428.7 485.3 471.3 472.7 514.2 465.2C557 457.7 600 455.3 642.8 460.2C685.7 465 728.3 477 771.2 478C814 479 857 469 878.5 464L900 459L900 601L878.5 601C857 601 814 601 771.2 601C728.3 601 685.7 601 642.8 601C600 601 557 601 514.2 601C471.3 601 428.7 601 385.8 601C343 601 300 601 257.2 601C214.3 601 171.7 601 128.8 601C86 601 43 601 21.5 601L0 601Z" fill={color2}></Path>
            <Path d="M0 547L21.5 545.7C43 544.3 86 541.7 128.8 536.8C171.7 532 214.3 525 257.2 519C300 513 343 508 385.8 505.2C428.7 502.3 471.3 501.7 514.2 507.8C557 514 600 527 642.8 532.8C685.7 538.7 728.3 537.3 771.2 536.5C814 535.7 857 535.3 878.5 535.2L900 535L900 601L878.5 601C857 601 814 601 771.2 601C728.3 601 685.7 601 642.8 601C600 601 557 601 514.2 601C471.3 601 428.7 601 385.8 601C343 601 300 601 257.2 601C214.3 601 171.7 601 128.8 601C86 601 43 601 21.5 601L0 601Z" fill={color3}></Path>
        </chakra.svg>
    )
}

export function White_wave({ color1, ...rest }) {
    return (
        // <chakra.svg {...rest} id="visual" viewBox="0 0 900 600" width="900" height="600" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" style={{ width: '100% ' }}>
        //     <Path d="M0 509L21.5 499.5C43 490 86 471 128.8 472.8C171.7 474.7 214.3 497.3 257.2 501.2C300 505 343 490 385.8 489.8C428.7 489.7 471.3 504.3 514.2 488.5C557 472.7 600 426.3 642.8 421.2C685.7 416 728.3 452 771.2 474.5C814 497 857 506 878.5 510.5L900 515L900 601L878.5 601C857 601 814 601 771.2 601C728.3 601 685.7 601 642.8 601C600 601 557 601 514.2 601C471.3 601 428.7 601 385.8 601C343 601 300 601 257.2 601C214.3 601 171.7 601 128.8 601C86 601 43 601 21.5 601L0 601Z" fill={color1} strokeLinecap="round" strokeLinejoin="miter"></Path>
        // </chakra.svg>
        <chakra.svg {...rest} id="visual" viewBox="0 0 900 150" width="900" height="150" preserveAspectRatio="none" xxmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" style={{ width: '100% ' }}>
            <Path d="M0 44L13.7 48.3C27.3 52.7 54.7 61.3 82 70.3C109.3 79.3 136.7 88.7 163.8 88.8C191 89 218 80 245.2 79.3C272.3 78.7 299.7 86.3 327 81.8C354.3 77.3 381.7 60.7 409 53.5C436.3 46.3 463.7 48.7 491 59.3C518.3 70 545.7 89 573 97.2C600.3 105.3 627.7 102.7 654.8 100.2C682 97.7 709 95.3 736.2 88C763.3 80.7 790.7 68.3 818 68C845.3 67.7 872.7 79.3 886.3 85.2L900 91L900 151L886.3 151C872.7 151 845.3 151 818 151C790.7 151 763.3 151 736.2 151C709 151 682 151 654.8 151C627.7 151 600.3 151 573 151C545.7 151 518.3 151 491 151C463.7 151 436.3 151 409 151C381.7 151 354.3 151 327 151C299.7 151 272.3 151 245.2 151C218 151 191 151 163.8 151C136.7 151 109.3 151 82 151C54.7 151 27.3 151 13.7 151L0 151Z" fill={color1} strokeLinecap="round" strokeLinejoin="miter"></Path>
        </chakra.svg>
    )
}