import React, { useState, useEffect, useCallback } from "react";
import NextLink from 'next/link';
import CustomLayout from "../components/layout";
import { momentumchartPageURL, subscribePageURL, signupPageURL, howtoPageURL, contactPageURL, privacyPageURL, termsPageURL, cookiePageURL, disclaimerPageURL, sitemapURL } from "../components/constants";
import {
  AspectRatio,
  Box,
  Button,
  Container,
  // chakra,
  Flex,
  // Grid,
  Heading,
  HStack,
  Icon,
  Image,
  Link,
  LinkBox,
  LinkOverlay,
  // Portal,
  SimpleGrid,
  // Stack,
  // StackDivider,
  Text,
  // useColorMode,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import styles from "../styles/Home.module.css";
import {
  Layered_waves_1,
  Layered_waves_2,
  Layered_waves_3,
  White_wave,
} from "../components/waves";
import {
  BsGraphUp,
  BsSearch,
} from "react-icons/bs";
import {
  GoChecklist
} from "react-icons/go";
import {
  IoIosWallet,
} from "react-icons/io";
import {
  FaPlay,
  FaRegUserCircle
} from "react-icons/fa";
import ReactPlayer from "react-player/lazy";


const Home = (props) => {
  const [embeddedVideo, setEmbeddedVideo] = React.useState(<Box />)


  const mobileImage = useColorModeValue(
    <Image
      // priority
      src={'/mobile-view-light.png'}
      alt={'Mobile chart view - light'}
      htmlWidth={530}
      htmlHeight={1135}
    />,
    <Image
      // priority
      src={'/mobile-view-dark.png'}
      alt={'Mobile chart view - dark'}
      htmlWidth={530}
      htmlHeight={1135}
    />
  )

  const desktopImage = useColorModeValue(
    <Image
      // priority
      src={'/desktop-view-light.png'}
      alt={'Desktop chart view - light'}
      htmlWidth={1920}
      htmlHeight={1189}
      borderRadius={6}
    />,
    <Image
      // priority
      src={'/desktop-view-dark.png'}
      alt={'Desktop chart view - dark'}
      htmlWidth={1920}
      htmlHeight={1189}
      borderRadius={6}
    />
  )

  const playButtonColor = useColorModeValue("blue.400", "blue.500");

  useEffect(() => {
    setEmbeddedVideo(
      <AspectRatio maxW={{ base: '90vw', md: '640px' }} boxSize={'100%'} ratio={16 / 9}>
        <ReactPlayer
          url="/Momentum Screener Getting Started.mp4"
          controls
          light={'/desktop-view-light.png'}
          playsinline
          pip
          // stopOnUnmout={false}
          width={'100%'}
          height={'100%'}
          config={{
            file: {
              attributes: {
                controlsList: "nodownload"
              }
            }
          }}
          onContextMenu={handleContextMenu}
          playIcon={<Icon as={FaPlay} w={16} h={16} color={playButtonColor} />}
        />
      </AspectRatio>
    )
  }, [])

  const handleContextMenu = useCallback((event) => {
    event.preventDefault();
  }, []);


  function Feature({ icon, title, desc, ...rest }) {
    return (rest.href ?
      <LinkBox
        p={5}
        shadow={useColorModeValue("2xl", "dark-lg")}
        borderWidth="1px"
        flex="1"
        borderRadius="md"
        {...rest}
      >
        <Icon as={icon} w={16} h={16} />
        <NextLink href={rest.href} passHref>
          <LinkOverlay>
            <Heading pt={5} fontSize="xl" color={'blue.500'}>{title}</Heading>
          </LinkOverlay>
        </NextLink>
        <Text mt={4}>{desc}</Text>
      </LinkBox> :
      <Box
        p={5}
        shadow={useColorModeValue("2xl", "dark-lg")}
        borderWidth="1px"
        flex="1"
        borderRadius="md"
        {...rest}
      >
        <Icon as={icon} w={16} h={16} />
        <Heading pt={5} fontSize="xl" color={'blue.500'}>{title}</Heading>
        <Text mt={4}>{desc}</Text>
      </Box>
    )
  }


  return (
    <CustomLayout>
      <Box overflowX={'hidden'}>
        <Box
          py={'12rem'}
          overflow={'clip'}
          bgGradient={useColorModeValue('linear(to-b, blue.50, blue.400)', 'linear(to-b, blue.900, blue.500)')}
        >
          <Container maxW={'container.xl'}>
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={{ base: 16, md: 6 }}>
              <Box>
                <VStack spacing={8} align={'start'}>
                  <Heading as='h1' size='4xl'>Momentum Screener</Heading>
                  <Text fontSize={'2xl'} textAlign="start">An innovative tool that analyzes all US market investments daily using proprietary algorithms.</Text>

                  <VStack spacing={1} align={'start'}>
                    <HStack>
                      <NextLink href={signupPageURL} passHref>
                        <Link>
                          <Button colorScheme={'blue'} variant={'solid'} size={'lg'}>Start Free Trial</Button>
                        </Link>
                      </NextLink>
                      <NextLink href={howtoPageURL} passHref>
                        <Link>
                          <Button borderColor={useColorModeValue('black', 'white')} variant={'outline'} size={'lg'}>Learn How</Button>
                        </Link>
                      </NextLink>
                    </HStack>
                    <Text textColor={'gray.700'} fontSize={'lg'}>*No credit card required during free trial.</Text>
                  </VStack>
                </VStack>
              </Box>
              <Box>
                <VStack spacing={8}>
                  <VStack spacing={4} w={'100%'}>
                    <Box
                      w={'100%'}
                      shadow={useColorModeValue("2xl", "dark-lg")}
                      borderWidth="1px"
                      borderRadius="md"
                    >
                      {embeddedVideo}
                    </Box>
                    <Heading size="md">Take a Tour of Momentum Screener!</Heading>
                  </VStack>
                </VStack>
              </Box>
            </SimpleGrid>
          </Container>
        </Box>
        <Layered_waves_2 color1={useColorModeValue('blue.100', 'blue.900')} color2={useColorModeValue('blue.200', 'blue.700')} color3={useColorModeValue('blue.400', 'blue.500')} />
        {/* <White_wave color1={useColorModeValue('bgLight', 'bgDark')} marginTop={'-8rem'} position={'relative'} zIndex={'docked'} /> */}
        <Container maxW={'container.xl'} mt={'-18rem'}>
          <SimpleGrid columns={{ sm: 1, md: 2, lg: 3 }} spacing={8}>
            <Feature
              icon={FaRegUserCircle}
              title='Create an Account'
              desc='Create your account today! You get a 60-day free trial to decide if it is right for you.'
              href={signupPageURL}
            />
            <Feature
              icon={GoChecklist}
              title='Pre-filtered Lists'
              desc='Explore the results presented by the proprietary algorithm to identify securites with momentum.'
              href={`${howtoPageURL}#ticker-table`}
            />
            <Feature
              icon={BsSearch}
              title='Search Securities'
              desc='Search for potential investments and review the historical graph to track potential growth.'
              href={`${howtoPageURL}#ticker-table-search`}
            />
            <Feature
              icon={BsGraphUp}
              title='Save Your Pick'
              desc='Create custom lists to track all of the securities you own or want to follow.'
              href={`${howtoPageURL}#ticker-table-favorites`}
            />
            <Feature
              icon={IoIosWallet}
              title="Think You're Not an Investor?"
              desc="Don't forget about what you own in your 401(k), HSA, or IRA!"
              href={`${howtoPageURL}#ticker-table-favorites`}
            />
            <Feature
              title="Need Help Getting Started?"
              desc="Check out our extensive How-To page. Contact us with any questions."
              href={howtoPageURL}
            />
          </SimpleGrid>
        </Container>
        <Layered_waves_1 mt={'-15rem'} color1={useColorModeValue('blue.100', 'blue.900')} color2={useColorModeValue('blue.200', 'blue.700')} color3={useColorModeValue('blue.400', 'blue.500')} />
        <Box background={useColorModeValue('blue.400', 'blue.500')} p={'5rem 0'}>
          {/* third Area White */}
          <Container maxW={'container.xl'}>
            <SimpleGrid
              columns={{ base: 1, md: 2 }}
              color={'white'}
              spacing={'5rem'}
            >
              <Flex boxSize={'100%'} align={'center'}>
                <Image
                  borderRadius={'md'}
                  src={'/pointing-at-laptop.jpg'}
                  alt={'People pointing at a laptop'}
                  htmlWidth={5184}
                  htmlHeight={3456}
                  layout={'intrinsic'}
                  area={{ base: '1 / 1 / 2 / 2', md: '1 / 1 / 2 / 2' }}
                />
              </Flex>
              <Box area={{ base: '2 / 1 / 3 / 2', md: '1 / 2 / 2 / 3' }}>
                <VStack spacing={8} height={'100%'} align={'start'} justify={'center'}>
                  <Heading size={'2xl'}>Tools For Success</Heading>
                  <Text fontSize={'lg'}>Momentum Screener is a software analysis tool that reviews and processes all US investments daily using proprietary algorithms. These algorithms rank and display investments against each other, presenting upward-trending investments over selected periods of time. Try Momentum Screener for FREE today; no credit card required.</Text>
                  <NextLink href={signupPageURL} passHref>
                    <Link>
                      <Button variant={'outline'} size={'lg'}>Start Free Trial</Button>
                    </Link>
                  </NextLink>
                </VStack>
              </Box>

              <Box area={{ base: '4 / 1 / 5 / 2', md: '2 / 1 / 3 / 2' }}>
                <VStack spacing={8} height={'100%'} align={'start'} justify={'center'}>
                  <Heading size={'2xl'}>Track Stocks and Crypotocurrencies</Heading>
                  <Text fontSize={'lg'}>Yes, that's right, track all of your investments&mdash;Stocks, Bonds, Mutual Funds and Cryptocurrencies, including what's inside your 401(k), HSA, and IRA. Save your favorites and use custom filters to search for new favorites. Once you start using Momentum Screener, you will never want to stop. Try Momentum Screener for FREE today; no credit card required.</Text>
                  <NextLink href={signupPageURL} passHref>
                    <Link>
                      <Button variant={'outline'} size={'lg'}>Start Free Trial</Button>
                    </Link>
                  </NextLink>
                </VStack>
              </Box>

              <Flex boxSize={'100%'} align={'center'}>
                <Image
                  borderRadius={'md'}
                  src={'/people-on-tablet.jpg'}
                  alt={'People learning on a tablet'}
                  htmlWidth={5930}
                  htmlHeight={3945}
                  layout={'intrinsic'}
                  justify={'center'}
                  area={{ base: '3 / 1 / 4 / 2', md: '2 / 2 / 3 / 3' }}
                />
              </Flex>
            </SimpleGrid>
          </Container>
        </Box>
        <Layered_waves_2 color1={useColorModeValue('blue.100', 'blue.900')} color2={useColorModeValue('blue.200', 'blue.700')} color3={useColorModeValue('blue.400', 'blue.500')} />
        {/* <Box m={'-18rem 0'}>
          <Container maxW={'container.xl'} align={'center'}>
            <VStack spacing={8}>
              <Heading size={'2xl'}>Take Your Investments to the Next Level</Heading>
              <Text fontSize={'xl'}>Get the most out of Momentum Screener by following our online video tutorials. </Text>
              <VStack spacing={4} w={'100%'}>
                {embeddedVideo}
                <Heading size="md">Momentum Screener in Action</Heading>
              </VStack>
            </VStack>
          </Container>
        </Box> */}
        <Box m={'-18rem 0 -18rem 0'} p={4}>
          <Flex direction="column" align="center" gap={8}>
            <Flex direction={["column", "row"]} justify="center" align="center" gap={8}>
              <Box
                w={'19rem'}
              >
                <AspectRatio ratio={1 / 2}>
                  <Box
                    borderRadius={'36px'}
                    boxShadow={'0 50px 100px -20px rgba(50,50,93,.25),0 30px 60px -30px rgba(0,0,0,.3),inset 0 -2px 6px 0 rgba(10,37,64,.35)'}
                    bg={useColorModeValue('gray.100', 'gray.600')}
                    padding={'8px'}
                  >
                    <Box
                      bg={useColorModeValue('white', 'black')}
                      w={'100%'}
                      h={'100%'}
                      borderRadius={'30px'}
                      sx={{ maskImage: useColorModeValue('-webkit-radial-gradient(#fff,#000)', '-webkit-radial-gradient(#000,#fff)') }}
                    >
                      {mobileImage}
                    </Box>
                  </Box>
                </AspectRatio>
              </Box>
              <Box
                background={useColorModeValue('linear-gradient(hsla(0,0%,100%,.4),hsla(0,0%,100%,.3) 25%,rgba(246,249,252,.3) 50%,#f6f9fc 60%)',
                  'linear-gradient(hsla(0,0%,100%,.4),hsla(0,0%,100%,.3) 25%,rgba(246,249,252,.3) 50%)')}
                boxShadow={'inset 0 1px 1px 0 hsla(0,0%,100%,.1),0 50px 100px -20px rgba(50,50,93,.25),0 30px 60px -30px rgba(0,0,0,.3)'}
                maxWidth={'65rem'}
                maxHeight={'42rem'}
                borderRadius={'8px'}
              >
                <Box p={{ base: '0.5rem', md: '2rem' }} >
                  {desktopImage}
                </Box>
              </Box>
            </Flex>
            <Box>
              <Text
                as={'i'}
                width={'90%'}
              >Available through desktop and mobile browsers.<br />Native smartphone apps coming soon!</Text>
            </Box>
          </Flex>
        </Box>
        <Layered_waves_3 color1={useColorModeValue('blue.100', 'blue.900')} color2={useColorModeValue('blue.200', 'blue.700')} color3={useColorModeValue('blue.400', 'blue.500')} />
      </Box>
    </CustomLayout>
  )
};

export default Home;
